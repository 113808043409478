import { useLocation } from 'react-router-dom';
import SideMenu from "../components/Dashboard/sideMenu";

export default function Dashboard() {
  const { state } = useLocation();
  //asdasdasd
  return (
    <div className='w-full h-screen grid sm:grid-cols-2 bg'>
      <SideMenu />
    </div>
  );
}

import React from 'react';
import { useNavigate } from 'react-router-dom';

type Props = {
  icon?: React.ReactElement;
  title: string;
  to: string;
};

const Button: React.FC<Props> = (props) => {
  const navigate = useNavigate();

  const redirectTo = () => {
    navigate(props.to);
  };

  return (
    <button
      id='Button'
      className="p-1 px-10 max-w-60  border border-red-600 rounded-md text-red-600 text-xl"
      onClick={redirectTo}
    >
      {props?.icon} {props.title}
    </button>
  );
};

export default Button;

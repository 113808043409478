import * as React from "react"

function BackCastro(props: any) {
  return (
    <svg
      width={790}
      height={712}
      viewBox="0 0 790 712"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M185.147 576.706C-32.638 657.474-14.068 811.378 22.44 878.234l969.485 135.216C1022.72 702.305 1047 66.949 897.726 14.666 711.13-50.687 618.83 133.247 602.604 194.093c-14.082 52.81-145.226 281.653-417.457 382.613z"
        fill="#BFE4CB"
      />
    </svg>
  )
}

export default BackCastro

import * as React from "react"

function IconLogo(props:any) {
  return (
    <svg
      width={58}
      height={58}
      viewBox="0 0 58 58"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M26.084 24.836h31.272c-.324-1.788-.648-3.732-1.296-5.352H27.224c-2.268 0-3.24-.816-2.916-2.76l3.084-16.2c-.816 0-1.62.168-2.268.168l-4.536 17.34c-1.308 4.86.312 6.804 5.496 6.804z"
        fill="#fff"
      />
      <path
        d="M24.296 33.26c-8.424 0-12.156-4.704-9.564-11.988L21.704 1.34c-.816.168-1.788.492-2.592.816l-8.748 20.256c-4.212 9.564 1.14 16.2 13.284 16.2h32.244c.648-1.788 1.14-3.564 1.296-5.352H24.296z"
        fill="#fff"
      />
      <path
        d="M27.224 19.496H56.06A28.485 28.485 0 0029.168.536h-1.62l-3.084 16.2c-.492 1.788.492 2.76 2.76 2.76z"
        fill="#009343"
      />
      <path
        d="M10.364 22.412L19.28 2.156C8.42 6.044.812 16.412.488 28.244c-.168 32.568 43.908 40.5 55.404 10.524H23.648c-12.144-.156-17.496-6.804-13.284-16.356z"
        fill="#009343"
      />
      <path
        d="M24.296 33.26h32.892c.168-1.464.324-2.916.324-4.38s-.168-2.76-.324-4.212H26.084c-5.184 0-6.804-1.944-5.508-6.804L25.112.524c-1.14.168-2.268.324-3.24.648L14.744 21.26c-2.592 7.308 1.128 12 9.552 12z"
        fill="#F00023"
      />
    </svg>
  )
}

export default IconLogo

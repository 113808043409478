import React from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Software from '../pages/software';
import Login from '../pages/login';
import Dashboard from '../pages/dashbord';
import { Teste } from '../pages/pageTeste';
import { Layout } from '../pages/LayoutDash';

const App: React.FC = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Layout><Teste /></Layout>} />
        <Route path="/outraRota" element={<Layout><Teste /></Layout>} />
        <Route path="/software" element={<Software />} />
        <Route path='/login' element={<Login />} />
        <Route path='/dashboard' element={<Dashboard />} />
      </Routes>
    </Router>
  );
};
export default App;
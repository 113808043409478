import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Breadcrumb } from "../breadcrumb"
type BreadcrumbProps = {};

export const HeaderDash: React.FC<BreadcrumbProps> = () => {
  const location = useLocation();
  const paths = location.pathname.split('/').filter((path) => path !== '');

  return (
    <>
      <div className="w-full">
        <Breadcrumb
          name='Home'
          route='/'
        />
      </div>
    </>
  );
};
import React, { useState, ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';

type SelectItemProps = {
  title?: string;
  to?: string;
};

export const SelectItem: React.FC<SelectItemProps> = ({ title, to }) => {
  return (
    <div className='hover:text-gray-300'>
      <a href={to}>{title}</a>
    </div>
  );
};

type SelectMenuProps = {
  title?: string;
  icon?: React.ReactElement | string;
  children?: ReactNode;
  to?: string;
  reduceMode?: boolean;
};

export const SelectMenu: React.FC<SelectMenuProps> = ({ title, children, to, icon, reduceMode }) => {
  const [showOptions, setShowOptions] = useState(false);
  const navigate = useNavigate();

  const handleClick = () => {
    if (React.Children.count(children) > 0) {
      // Se houver filhos, executa o toggleOptions
      toggleOptions();
    } else if (to) {
      // Se não houver filhos e houver uma URL, redireciona para a URL
      navigate(to);
    }
    // Adicione aqui qualquer outra lógica desejada para o caso sem filhos e sem URLasdasd
  };

  const toggleOptions = () => {
    setShowOptions((prev) => !prev);
  };

  const renderIcon = () => {
    if (typeof icon === 'string') {
      return <span className="material-symbols-outlined text-sm ms:col-span-1  text-center md:text-left">{icon}</span>;
    }
    return icon;
  };

  return (
    <>
      <button
        title={`Botão que o leva para ${title}`}
        className={`text-slate-500 w-full ${showOptions ? 'bg-green-700 text-white' : ''} text-left font-semibold  hover:bg-gray-300 hover:text-black/50 rounded-md grid p-2 ${reduceMode ? "grid-cols-1" : "grid-cols-5"} items-center gap-x-3 button-container`}
        onClick={handleClick}
      >
        {renderIcon()}
        <p className={`text-wrap text-sm grid-flow-col ${reduceMode ? "" : "col-span-3"}`}>
          {!reduceMode && title}
        </p>
        {!reduceMode && (
          <div className="">
            <span className={`material-symbols-outlined transition col-span-1 flex justify-center items-center ${showOptions ? 'rotate-90' : ''}`}>
              navigate_next
            </span>
          </div>
        )}
      </button>
      {showOptions && (
        <div className='border-l-2 border-green-600 flex justify-start flex-col gap-y-2 pl-3 relative left-5 transition-opacity '>
          <div className='transition-opacity'>
            {children}
          </div>
        </div>
      )}
    </>
  );
};

import React from 'react';
import iconNext from "../assets/arrow.svg";
import { title } from 'process';


type Props = {
  icon: React.ReactElement;
  title: string;
  subTitle?: string;
};

const Card: React.FC<Props> = (props) => {
  return (
    <button id='card' title={`Clique aqui e seja levado para ${props.title}`} className="p-2 flex flex-col  md:grid md:grid-cols-5 border-[1.24px] border-gray-500 w-full rounded-xl justify-center items-center ">
      {React.cloneElement(props.icon, { key: props.title, className: "w-8/12 md:w-[120%]" })}
      <div className="w-full col-span-3 flex flex-col justify-center break-words  sm:px-4">
        <h2 className='font-bold text-sm'>{props.title}</h2>
        <span className='text-xs'>{props.subTitle}</span>
      </div>
      <div className="hidden md:flex h-full w-full arrow justify-end items-center pr-2">
        <img src={iconNext} alt="" className='' />
      </div>
    </button>
  );
};

export default Card;

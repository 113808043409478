import { useLocation } from "react-router-dom";
import Logo from "../assets/Logo";
import BackgroundLogin from "../assets/img/background_Login.jpg";
import LambdaIcon from "../assets/lambdaIcon";
import Button from "../components/button";

export default function Login() {
  const { state } = useLocation();
  const backgroundStyle = {
    backgroundImage: `url(${BackgroundLogin})`,
    backgroundSize: "cover", // Ajuste de acordo com suas necessidades
    backgroundPosition: "center", // Ajuste de acordo com suas necessidades
    // Adicione mais estilos conforme necessário
  };
  return (
    <div className="w-full h-screen grid sm:grid-cols-2 relative">
      <div className="flex flex-col justify-center items-center text-left gap-y-10 :pl-[20%]">
        <Logo />
        <div className="flex flex-col gap-y-9 justify-center items-center ">
          <p className="text-2xl text-center md:text-left">
            Seja bem-vindo(a) ao <b className="text-green-700">Painel</b> <br />{" "}
            <b className="text-green-700">Administrativo Castrol®</b>
          </p>
          <Button title="Acessar" to="/software" />
        </div>
        <div className="relative top-60">
          <p className="text-sm flex justify-center text-slate-400 gap-1">
            {" "}
            <LambdaIcon />
            LeadSoft® Soluções Web
          </p>
        </div>
      </div>
      <div className="hidden sm:block" style={backgroundStyle}></div>
    </div>
  );
}

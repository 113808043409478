import { useLocation } from "react-router-dom";
import Logo from "../assets/Logo";
import Service from "../assets/service";
import Garantia from "../assets/garantia";
import Adm from "../assets/adm";
import App from "../assets/iconLogo";
import Castro from "../assets/castrol";
import Card from "../components/card";
import BackCastro from "../assets/backCastro";
import LambdaIcon from "../assets/lambdaIcon";

export default function Software() {
  const { state } = useLocation();

  return (
    <div className="w-full h-screen grid sm:grid-cols-2">
      <div className="flex justify-center items-center flex-col gap-11 p-6 relative">
        <Logo />
        <p className="text-green-700 text-3xl font-semibold text-center">
          Olá, Isabella! Qual sistema você deseja acessar?
        </p>
        <div className="grid grid-cols-2 gap-4 max-w-">
          <Card
            icon={<Adm />}
            title="Painel Administrativo"
            subTitle="Sistema"
          />
          <Card
            icon={<Garantia />}
            title="Garantia de motor"
            subTitle="Sistema"
          />
          <Card icon={<Service />} title="Sistema CAS" subTitle="Sistema" />
          <Card
            icon={<App />}
            title="Aplicativo do trocador"
            subTitle="Sistema"
          />
        </div>
        <div className="">
          <p className="absolute left-[4%] bottom-[2%] text-sm flex justify-center text-slate-400  gap-1">
            {" "}
            <LambdaIcon />
            LeadSoft® Soluções Web
          </p>
        </div>
      </div>
      <div className="hidden sm:flex justify-end items-end relative">
        <Castro className="z-30 w-6/12" />
        <BackCastro className="absolute right-0 bottom-0 w-11/12 h-2/5  lg:h-4/6 " />
      </div>
    </div>
  );
}

import React, { useEffect, useState } from "react";
import LogoTipo from "../../assets/Logo";
import LambdaIcon from "../../assets/lambdaIcon";
import { SelectMenu, SelectItem } from "../select";
import IconLogo from "../../assets/iconLogo";

const SideMenu: React.FC = () => {
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const [collapsed, setcollapsed] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 900);
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const toggleMenu = () => {
    setcollapsed(!collapsed); // Inverte o estado do collapsed
    setIsSmallScreen(!isSmallScreen);
  };

  return (
    <>
      <div
        className={`transition-transform duration-300 ease-in-out  h-full border-r-8 border-green-700 p-2 relative`}
      >
        <div className="border-b flex justify-center items-center p-4 mb-4 ">
          <LogoTipo
            className={`w-9/12 ${isSmallScreen ? "hidden" : "block"}`}
          />
          <IconLogo
            className={`w-9/12 ${isSmallScreen ? "block min-w-10" : "hidden"}`}
          />
        </div>
        <div className="flex flex-col gap-y-3">
          <SelectMenu
            icon="grid_view"
            title="Customizar Produtos"
            to="/"
            reduceMode={isSmallScreen}
          />
          <SelectMenu
            icon="business_center"
            title="App do trocador"
            reduceMode={isSmallScreen}
          >
            <SelectItem title="Videos" to="/software" />
            <SelectItem title="Máquinas" to="/login" />
          </SelectMenu>
          <SelectMenu
            icon="deployed_code"
            title="Gerenciar Credenciais"
            to="/"
            reduceMode={isSmallScreen}
          />
          <SelectMenu
            icon="person"
            title="Pontos de Venda"
            to="/"
            reduceMode={isSmallScreen}
          />
          <button
            onClick={toggleMenu}
            className="absolute top-96 ml-[98%] md:hidden bg-green-700  w-6 h-12 rounded-r-lg flex justify-center items-center  shadow-2xl"
          >
            <span
              className={`material-symbols-outlined text-white ${
                isSmallScreen ? "" : "rotate-180"
              }`}
            >
              navigate_next
            </span>
          </button>
        </div>
        <div className="">
          <p
            className={`${
              isSmallScreen ? "hidden" : "absolute"
            } left-[4%] bottom-[2%] text-xs flex justify-center text-slate-400 gap-1`}
          >
            {" "}
            <LambdaIcon />
            LeadSoft® Soluções Web
          </p>
        </div>
      </div>
    </>
  );
};

export default SideMenu;

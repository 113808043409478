import React from 'react';
import ReactDOM from 'react-dom/client';
import Routes from './routes/routes';
import reportWebVitals from './reportWebVitals';
import './globals.css';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <>
    <html lang="en">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@24,300,0,0" />
        <title>Oil Panel</title>
      </head>
      <body>
        <React.StrictMode>
          <Routes />
        </React.StrictMode>
      </body>
    </html>
  </>
);

reportWebVitals();
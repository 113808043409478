import * as React from "react"

function SvgComponent(props: any) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 150 39"
      className='w-1/3'
      {...props}
    >
      <defs>
        <image
          width={150}
          height={39}
          id="img1"
          href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAJYAAAAnAQMAAADXWSNxAAAAAXNSR0IB2cksfwAAAANQTFRF////p8QbyAAAABBJREFUeJxjYBgFo2AUgAAAAwwAAW4bBIEAAAAASUVORK5CYII="
        />
      </defs>
      <style>{".s0{fill:#fff}.s1{fill:#009343}.s2{fill:#f00023}"}</style>
      <use id="Background" href="#img1" x={0} y={0} />
      <g id="Layer">
        <g id="Layer">
          <path
            id="Layer"
            className="s0"
            d="M15.8 16h19.3c-.2-1.1-.4-2.3-.8-3.3H16.5c-1.4 0-2-.5-1.8-1.7l1.9-10c-.5 0-1 .1-1.4.1l-2.8 10.7c-.8 3 .2 4.2 3.4 4.2z"
          />
          <path
            id="Layer"
            className="s0"
            d="M14.7 21.2c-5.2 0-7.5-2.9-5.9-7.4l4.3-12.3c-.5.1-1.1.3-1.6.5L6.1 14.5c-2.6 5.9.7 10 8.2 10h19.9c.4-1.1.7-2.2.8-3.3z"
          />
          <path
            id="Layer"
            className="s1"
            d="M16.5 12.7h17.8C31.9 5.9 25.4 1 17.7 1h-1l-1.9 10c-.3 1.1.3 1.7 1.7 1.7z"
          />
          <path
            id="Layer"
            className="s1"
            d="M6.1 14.5L11.6 2C4.9 4.4.2 10.8 0 18.1c-.1 20.1 27.1 25 34.2 6.5H14.3c-7.5-.1-10.8-4.2-8.2-10.1z"
          />
          <path
            id="Layer"
            className="s2"
            d="M14.7 21.2H35c.1-.9.2-1.8.2-2.7 0-.9-.1-1.7-.2-2.6H15.8c-3.2 0-4.2-1.2-3.4-4.2L15.2 1c-.7.1-1.4.2-2 .4L8.8 13.8c-1.6 4.5.7 7.4 5.9 7.4z"
          />
        </g>
        <g id="Layer">
          <path
            id="Layer"
            className="s2"
            d="M41.1 20.2c0-7.3 5.4-12.9 12.7-12.9 4.6 0 8.2 2.7 9.7 6.9l-5.1 1.4c-.8-2.3-2.7-3.5-5.1-3.5-4.2 0-7.2 3.2-7.2 7.6 0 3.5 2.2 5.7 5.8 5.7 2.4 0 4.6-1.3 5.7-3.5l4.5 2.3c-2.1 3.7-6.1 6.1-10.7 6.1-6.2 0-10.3-4.4-10.3-10.1z"
          />
          <path
            id="Layer"
            fillRule="evenodd"
            className="s2"
            d="M63.1 22.1c0-5.7 4.3-10.4 9.6-10.4 2.5 0 4.3.8 5.5 2.6l.9-2.1h3.8L81 29.9h-4.5l.2-2.1c-1.6 1.7-3.6 2.6-6 2.6-4.4 0-7.6-3.6-7.6-8.3zm14.4-1.7c0-2.6-1.8-4.3-4.4-4.3-3.1 0-5.4 2.4-5.4 5.6 0 2.6 1.7 4.3 4.4 4.3 3 0 5.4-2.4 5.4-5.6z"
          />
          <path
            id="Layer"
            className="s2"
            d="M101.8 23.9l.8-7.3h-3.1l.5-4.4h3.1l.4-3.6 4.7-1.4-.5 4.9h4l-.5 4.4h-4l-.8 7.3c-.1 1.4.6 1.9 2.4 1.9.4 0 .9 0 1.4-.1l-.5 4.1c-.9.1-1.7.1-2.4.1-4.5.2-5.9-1.8-5.5-5.9zM112.2 29.9l1.9-17.6h3.8l.5 2.1c1.4-1.6 3.6-2.4 5.6-2.4l-.5 4.7h-.7c-2.5 0-4.8 1.4-5.1 4.5l-.9 8.7z"
          />
          <path
            id="Layer"
            fillRule="evenodd"
            className="s2"
            d="M123.3 22.1c0-5.7 4.6-10.3 10.3-10.3 4.9 0 8.4 3.6 8.4 8.3 0 5.7-4.6 10.3-10.3 10.3-4.9 0-8.4-3.6-8.4-8.3zm14.1-1.7c0-2.5-1.7-4.2-4.3-4.2-3 0-5.3 2.4-5.3 5.5 0 2.5 1.7 4.2 4.3 4.2 3 0 5.3-2.3 5.3-5.5z"
          />
          <path
            id="Layer"
            className="s2"
            d="M143.1 29.9l2.2-21.2 4.7-1.4-2.4 22.6z"
          />
          <g id="Layer">
            <path
              id="Layer"
              className="s2"
              d="M82.7 25.5l4.6-1.6c.6 1.5 1.7 2.3 3.3 2.3 1.6 0 2.5-.6 2.5-1.4 0-2.5-8.4-1.4-8.4-6.8 0-3.6 3.1-6.2 7.3-6.2 3 0 5.3 1.8 6.2 4.9L93.7 18c-.4-1.4-1.1-2.1-2.3-2.1-1.2 0-2 .7-2 1.5 0 2.5 8.5 1 8.5 6.9 0 3.6-3.1 6.1-7.8 6.1-3.7 0-6.2-1.7-7.4-4.9z"
            />
          </g>
        </g>
      </g>
    </svg>
  )
}

export default SvgComponent

import React from 'react';
import { Link, useLocation } from 'react-router-dom';

type BreadcrumbProps = {
  name?: string;
  route?: string;
};

export function Breadcrumb({ name, route }: BreadcrumbProps) {
  const location = useLocation();
  const paths = location.pathname.split('/').filter((path) => path !== '');

  const currentName = name || (paths.length > 0 ? paths[paths.length - 1] : '');
  const currentRoute = route || location.pathname;

  return (
    <nav aria-label="breadcrumb" className="w-full flex items-center p-x-4 shadow-lg border ">
      <ul className='flex p-4 gap-x-'>
        <li className='text-green-700'>
          <Link
            to={currentRoute}
            className={`text-2xl`}
          >
            {currentName}
          </Link>

          {/* Adicionar seta (→) como separador, exceto para o último item */}
          {paths.length > 1 &&
            <span className="mx-2">
              <span className={`material-symbols-outlined transition`}>navigate_next</span>
            </span>}
        </li>
      </ul>
    </nav>
  );
}

import * as React from "react"

function Castro(props: any) {
  return (
    <svg
      viewBox="0 0 436 528"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g filter="url(#filter0_d_5727_28081)">
        <path
          d="M394.965 459.179c-1.824-25.425 0-49.026 0-49.026s-2.352-35.522-7.772-67.598c0 0-67.787 9.706-67.692 10.949.703 9.489 6.272 62.394 11.503 111.298 19.653-4.947 36.738-8.3 63.961-5.623z"
          fill="url(#paint0_linear_5727_28081)"
        />
        <path
          d="M292.9 235.705c-5.758-1.433-12.017-2.608-18.87-3.568l1.974-41.7c.743-12.719-12.801-23.303-30.291-23.559-16.761-.244-31.346 9.934-32.076 22.654l-2.203 41.632c-7.678.892-14.571 2.122-21.1 3.744l-.635 38.239 102.417 3.19.784-40.632z"
          fill="url(#paint1_linear_5727_28081)"
        />
        <path
          d="M390.559 365.615c-4.258-52.391-6.975-70.463-24.168-90.887-11.489-13.652-57.055-35.468-74.329-39.253l-.149-.662c-4.379-14.639-17.193-14.409-17.193-14.409-3.907 23.317-31.954 29.386-31.954 29.386-25.114-9.976-30.643-26.385-30.643-26.385-10.016-.23-16.261 7.204-18.369 10.191-29.575.757-89.238 13.652-112.96 16.586-25.953 3.19-40.05 4.163-65.76 21.316C3.667 279.081.49 296.977.044 308.642c-.487 12.354 3 37.536 13.247 42.375 9.488 4.474 47.336 4.258 51.364 2.014 2.42-1.351 7.934-7.069 10.205-6.974 29.94 1.203 51.904 2.649 56.757 3.46-4.893 49.012-9.394 137.371-7.637 162.918.338 4.92 8.042 9.759 8.813 11.368 7.677 16.017 198.562 15.409 199.373 2.865.135-2.149 8.935-8.502 9.381-12.773 2.798-26.561 4.109-86.737 4.501-141.21 16.355-2.569 36.59-6.894 44.511-7.07z"
          fill="url(#paint2_linear_5727_28081)"
        />
        <path
          d="M127.9 525.128c-3.068 58.379-3.933 85.778-3.933 85.778s60.812 5.001 126.761 4.46c42.862-.351 79.438 2.839 82.223-14.598 2.514-15.747 5.812-34.522 5.866-74.491 0-1.027-2.609-4.42-4.366-4.231-10.719 1.095-45.971 5.488-103.147 4.772-62.867-.784-90.739-4.853-98.7-5.542-1.203-.108-4.65 2.649-4.717 3.852h.013z"
          fill="#333844"
        />
        <path
          d="M292.251 235.516c-3.636 7.245-14.395 28.129-18.991 30.494-4.407 2.271-20.6-11.368-27.182-17.18-2.042.662-3.326.933-3.326.933a70.013 70.013 0 01-3.014-1.284c-7.11 4.339-23.046 13.584-28.994 13.03-6.677-.608-14.557-21.654-17.193-27.939-1.176.04-2.798.108-3.974.175 2.676 7.313 11.408 32.049 16.491 35.604 5.974 4.176 36.292-16.788 36.292-16.788s24.114 22.83 31.481 22.113c6.312-.608 18.383-30.034 21.708-38.442-1.149-.27-2.257-.513-3.298-.743v.027z"
          fill="#00A547"
        />
        <path
          d="M233.737 526.601l10.719-276.311-3.826-.122s-8.488 227.245-11.421 276.311l4.528.122z"
          fill="#00A547"
        />
        <path
          d="M398.358 404.895c.581.905 2.933 53.526.825 55.256-.284.23-4.272.784-4.272.784-13.341-2.73-44.848 1.677-53.188 4.61-1.311.459-2.217-.365-2.352-.717-1.19-3.095-2.947-54.229-3.014-58.014-.082-3.798 60.298-4.596 62.015-1.906l-.014-.013z"
          fill="url(#paint3_linear_5727_28081)"
        />
        <path
          d="M48.759 105.89s-11.192-33.414-9.34-40.2c1.852-6.785 8.286-7.38 10.016-3.54 1.73 3.838 12.665 35.9 14.03 37.292 0 0 1.69-1.122 1.622-1.797-.067-.69-16.071-46.066-12.408-52.594 3.676-6.543 9.002-4.002 9.002-4.002s2.217-8.88 10.124-6.852c7.907 2.027 22.208 49.769 22.938 50.255.73.487 3.163-.013 3.258-.568.08-.554-8.705-40.712-7.191-43.74 1.514-3.028 7.745-6.272 12.097.635 4.353 6.907 17.843 61.272 20.978 66.909 3.136 5.636 11.449-17.856 21.763-24.047 10.313-6.177 14.53-.784 15.044 1.784.513 2.569-7.664 11.044-10.57 21.506-2.893 10.462-11.665 39.509-22.628 49.201-10.962 9.691-11.462 11.8-11.462 11.8l-55.419-2.311s3.393-9.449 2.676-10.706c-2.77-4.92-12.976-28.899-14.544-49.012l.014-.013z"
          fill="#FF9E78"
        />
        <path
          d="M49.745 102.739c3.96 1.933 10.422-3.325 12.247-6.447-3.393-8.448-11.138-30.967-12.571-34.144-1.73-3.839-8.15-3.244-10.016 3.542-1 3.636 1.757 14.895 4.514 24.587 1.947 6.353 4.042 11.597 5.813 12.476l.013-.014z"
          fill="url(#paint4_linear_5727_28081)"
        />
        <path
          d="M64.465 95.698c1.649 1.014 25.087-8.38 29.832-12.26-2.582-6.947-15.234-47.376-22.492-49.242C63.897 32.17 61.68 41.05 61.68 41.05s-5.325-2.542-9.002 4c-3.244 5.772 8.867 41.835 11.787 50.662v-.014z"
          fill="url(#paint5_linear_5727_28081)"
        />
        <path
          d="M102.907 40.752c-4.352-6.907-10.583-3.663-12.097-.635-1.311 2.608 5.055 32.819 6.772 41.456 3.095.31 13.76-1.487 17.207-3.61-4.583-16.165-9.543-33.507-11.882-37.211z"
          fill="url(#paint6_linear_5727_28081)"
        />
        <path
          d="M80.01 88.75c-.04-.514-14.828-43.227-17.518-49.81-.581 1.176-.811 2.109-.811 2.109s-.406-.19-1.068-.338c2.095 6.366 16.369 49.553 17.288 49.458 1-.095 2.15-.865 2.095-1.42h.014z"
          fill="url(#paint7_linear_5727_28081)"
        />
        <path
          d="M120.601 162.524s-15.558 14.882-22.83 43.876c-2.852 11.381-15.963 99.227-30.616 117.421C55.734 338 26.16 352.666 16.697 346.678c-9.448-5.988-13.368-47.39-9.056-60.583 6.582-20.153 56.892-121.949 55.77-130.856-.365-2.879-2.257-7.745-2.257-7.745l59.433 15.03h.014z"
          fill="#FF9E78"
        />
        <path
          d="M156.597 81.153c-2.406-.797-5.961-.513-10.949 2.474-10.313 6.177-18.64 29.683-21.762 24.046-.257-.459-.595-1.27-.973-2.338-2.744.122-7.394 1.406-14.139 6.772-11.651 9.286-15.342 24.925-15.49 25.52 0 0 9.732 12.827 27.317 9.867 17.586-2.947 23.803-36.644 25.182-40.132 1.379-3.487 10.124-17.788 11.381-22.519.527-1.987.338-3.055-.567-3.703v.013z"
          fill="url(#paint8_linear_5727_28081)"
        />
        <path
          d="M160.692 85.411c-.244-1.243-1.366-3.15-3.623-4.082-.162.068-.216.203-.067.365 3.027 3.65-10.706 20.64-13.26 31.4-1.095 4.582-8.895 23.952-17.18 31.264-5.948 5.245-17.072 7.529-26.602 7.529-4.582 0-19.95 5.839-29.52 2.514-8.435-2.933-20.465-39.82-20.694-40.672 3.068 18.072 11.137 36.874 13.557 41.159.378.662-.406 3.663-1.23 6.326-.649 1.96-1.514 4.311-2.555 7.001 5.353.919 27.818 2.623 32.143 10.868 4.84 9.218-27.182 122.138-31.183 128.248-4.001 6.109-33.279 17.693-36.86 16.004-2.907-1.366-12.49-27.453-15.991-37.185-4.285 13.26-.365 54.567 9.07 60.542 9.448 5.988 39.036-8.678 50.458-22.857 14.652-18.194 27.764-106.04 30.616-117.421 4.731-18.897 12.989-31.805 18.166-38.496h.095s.027-.095.162-.338c1.866-2.392 3.298-3.933 3.974-4.623 1.676-1.689 4.015-3.92 7.313-6.826 10.962-9.691 19.734-38.739 22.627-49.201 2.893-10.462 11.084-18.924 10.57-21.505l.014-.014z"
          fill="url(#paint9_linear_5727_28081)"
        />
        <path
          d="M88.607 178.691c-3.947-4.204-24.722-6.705-30.345-7.299-11.841 29.291-40.524 88.711-48.945 110.189 3.069 13.476 11.408 39.05 14.314 41.767 3.731 3.487 22.087-3.907 30.075-12.922 7.989-9.016 39.415-126.95 34.9-131.735z"
          fill="url(#paint10_linear_5727_28081)"
        />
        <path
          d="M344.345 464.761c10.976-2.852 38.374-6.326 50.566-3.825 0 0 3.974-.554 4.272-.784.987-.811 1-12.746.676-25.317-1.366 5.393-2.244 12.449-4.893 13.246-3.907 1.176-52.824 4.393-54 4.001-1.176-.392-5.853-45.281-4.609-45.714 1.257-.419 60.798-1.987 60.825-1.987a25.23 25.23 0 00-2.69-.419 606.827 606.827 0 00-.567-6.69c-13.152-.92-61.38.554-61.38.554l-7.326-89.252s-3.974 1.217-5.731 1.217c-1.758 0 10.218 193.142 6.974 195.169-3.257 2.041-86.278 8.948-93.333 8.989-7.043.027-91.442-9.8-95.105-13.868-3.663-4.069 10.611-198.563 10.611-198.563s-6.123-.581-7.245-.351c-1.136.23-7.002 40.375-7.678 40.145-.676-.23-55.284-5.664-55.284-5.664 2.717-5.893 12.463-41.915 12.463-41.915s-3.623-.23-4.299-.23c-.675 0-12.435 40.05-17.53 43.876-5.097 3.825-36.915 10.813-49.959 5.163-9.935-4.312-14.517-36.766-16.14-52.445C1.059 296.504.26 303.222.059 308.615c-.487 12.354 3 37.536 13.246 42.375 9.49 4.474 47.336 4.258 51.364 2.014 2.42-1.352 7.935-7.069 10.205-6.975 29.94 1.203 50.215 2.001 55.082 2.798-2.609 34.671-7.705 138.034-5.948 163.581.338 4.92 8.043 9.759 8.813 11.368 0 0 51.026 4.149 98.605 4.609 50.013.473 101.971-3.298 101.971-3.298.136-2.149 7.719-6.934 8.165-11.206 1.189-11.3 2.108-28.682 2.811-49.133l-.027.013z"
          fill="url(#paint11_linear_5727_28081)"
        />
        <path
          d="M343.723 462.139c0 27.412 12.936 97.51 9.8 109.77-3.136 12.273-14.355 20.356-15.788 29.237-1.432 8.881-4.014 48.674-6.474 54.419-2.46 5.744 9.61 8.556 13.828 3.163 4.217-5.393 8.786-25.196 11.137-27.899 2.339-2.69 7.029 5.745 7.029 5.745s-2.041 20.518-2.757 20.721c-.717.203-19.694 3.96-23.884 8.232-4.191 4.271.337 10.083 1.365 10.259 1.027.176 20.87-1.027 24.708-2.217 3.853-1.203 10.003-3.852 10.003-3.852s7.096-1.622 8.894-3.163c1.798-1.541 19.329-27.615 20.181-34.968.851-7.353-7.786-50.188-7.786-52.594 0-2.406 3.176-120.814 1.298-123.368-1.879-2.568-51.581 4.231-51.581 6.515h.027z"
          fill="#FF9E78"
        />
        <path
          d="M390.275 462.22c3.055 3.636 4.947 62.231 4.947 62.272.636-32.4 1.095-67.476.081-68.868-1.878-2.568-51.742 4.231-51.742 6.515 0 26.114 11.435 90.955 9.975 107.648 1.46-.662.068-98.186.257-101.566.379-6.893 35.185-7.555 36.482-6.001z"
          fill="url(#paint12_linear_5727_28081)"
        />
        <path
          d="M399.088 414.641c-.284-5.623-.568-9.502-.716-9.746-.325-.5-2.704-.878-6.353-1.148-22.817-1.163-54.784.554-55.635 2.622-1.217 2.906 0 9.489.31 11.084.068.365 51.77-1.703 62.421-2.812h-.027z"
          fill="#00C057"
        />
        <path
          d="M295.062 368.711l1.906-18.261 4.042-1.19-2.028 19.451h-3.92zM243.049 364.94l3.974-1.338c.514 1.311 1.46 1.973 2.852 1.973s2.122-.513 2.122-1.243c0-2.19-7.204-1.19-7.204-5.894 0-3.095 2.676-5.352 6.312-5.352 2.582 0 4.528 1.514 5.312 4.217l-3.852 1.095c-.298-1.217-.973-1.825-2.001-1.825-1.027 0-1.73.581-1.73 1.311 0 2.163 7.286.879 7.286 5.961 0 3.136-2.704 5.285-6.745 5.285-3.19 0-5.353-1.433-6.353-4.19M185.901 353.896c-1.217 0-1.703-.459-1.514-1.486l1.635-8.624c.271-.027.582-.027.879-.027a15.166 15.166 0 0114.341 10.151h-15.341v-.014zm-14.193 5.042c0-6.528 4.136-12.097 9.962-14.247l-4.704 10.787c-2.217 5.109.581 8.624 7.042 8.624h17.167c-2.095 5.839-7.718 10.029-14.274 10.029-8.38 0-15.193-6.799-15.193-15.193z"
          fill="#fff"
        />
        <path
          d="M184.387 361.249c-4.461 0-6.475-2.46-5.11-6.352l3.772-10.665a19.197 19.197 0 011.73-.338l-2.433 9.232c-.663 2.581.175 3.609 2.946 3.609h16.653c.095.703.149 1.46.149 2.19 0 .783-.054 1.554-.176 2.311h-17.531v.013zM207.108 360.357c0-6.312 4.677-11.084 10.909-11.084 3.946 0 7.082 2.312 8.38 5.921l-4.379 1.243c-.663-1.946-2.312-3.041-4.434-3.041-3.609 0-6.231 2.771-6.231 6.556 0 2.973 1.919 4.947 5.015 4.947 2.095 0 3.973-1.122 4.92-3.041l3.852 1.946c-1.825 3.217-5.285 5.285-9.205 5.285-5.285 0-8.84-3.771-8.84-8.745M238.495 360.561c0-2.217-1.554-3.731-3.825-3.731-2.65 0-4.65 2.095-4.65 4.825 0 2.217 1.487 3.731 3.771 3.731 2.676 0 4.704-2.095 4.704-4.825zm-12.422 1.486c0-4.92 3.731-8.961 8.259-8.961 2.122 0 3.703.73 4.744 2.216l.784-1.797h3.312l-1.609 15.193h-3.92l.217-1.798c-1.366 1.487-3.069 2.217-5.191 2.217-3.771 0-6.596-3.069-6.596-7.083M259.513 363.602l.662-6.313h-2.649l.392-3.771h2.649l.338-3.068 4.042-1.19-.46 4.258h3.434l-.392 3.771h-3.434l-.662 6.313c-.122 1.243.541 1.608 2.068 1.608.338 0 .784-.027 1.217-.054l-.392 3.555a16.18 16.18 0 01-2.041.122c-3.947 0-5.137-1.731-4.772-5.231zM268.421 368.711l1.609-15.193h3.311l.419 1.798c1.19-1.392 3.069-2.095 4.826-2.095l-.419 4.068h-.636c-2.162 0-4.136 1.244-4.406 3.893l-.784 7.529h-3.92zM290.196 360.561c0-2.163-1.459-3.65-3.676-3.65-2.582 0-4.555 2.041-4.555 4.744 0 2.163 1.46 3.65 3.676 3.65 2.582 0 4.555-2.041 4.555-4.744zm-12.178 1.432a8.863 8.863 0 018.907-8.907c4.191 0 7.232 3.068 7.232 7.137a8.864 8.864 0 01-8.908 8.907c-4.19 0-7.231-3.068-7.231-7.137z"
          fill="#fff"
        />
        <path
          d="M187.371 174.89c7.406-1.803 11.272-12.043 8.636-22.872-2.636-10.83-10.776-18.147-18.182-16.345-7.405 1.803-11.272 12.043-8.636 22.872 2.636 10.83 10.776 18.147 18.182 16.345z"
          fill="url(#paint13_linear_5727_28081)"
        />
        <path
          d="M221.396 39.63s0-.121-.027-.325c.014.204.027.325.027.325z"
          fill="#3E1208"
        />
        <path
          d="M300.104 75.044c-3.23-25.25-73.68-39.523-95.726-5.745-7.191 15.774-15.734 45.417-20.343 74.937 0-.324.027-.513.027-.513s-22.573 76.573 47.525 82.885c67.463 6.083 79.588-26.871 78.168-73.072.96-25.925-5.001-59.014-9.637-78.506l-.014.014z"
          fill="url(#paint14_linear_5727_28081)"
        />
        <path
          d="M325.259 139.924c-7.083-2.825-15.963 3.353-19.843 13.801-3.879 10.449-1.27 21.208 5.813 24.033 7.083 2.825 15.963-3.352 19.842-13.801 3.88-10.448 1.271-21.208-5.812-24.033z"
          fill="#FF9E78"
        />
        <path
          d="M324.949 164.904c-7.124 4.203-14.477 2.5-19.681-2.055a62.702 62.702 0 01-1.068 10.205h.136l-1.271 26.696c3.163-6.623 4.961-14.409 5.853-23.154a9.627 9.627 0 002.081 1.176c7.083 2.825 15.964-3.353 19.843-13.801 1-2.69 1.554-5.407 1.73-7.989-1.406 3.582-3.879 6.732-7.623 8.935v-.013z"
          fill="url(#paint15_linear_5727_28081)"
        />
        <path
          d="M312.716 158.699l6.528 1.176c.298-10.354 8.111-13.53 8.111-13.53-12.314-4.948-14.639 12.354-14.639 12.354z"
          fill="url(#paint16_linear_5727_28081)"
        />
        <path
          d="M316.338 49.78c-4.082-7.744-9.678-9.245-15.977-8.015 3.217-1.987 2.339-5.014 2.339-5.014-3.704-.65-5.975 1.757-5.975 1.757 4.325-4.447 1.892-11.044 1.892-11.044-10.029 3.96-8.177 9.057-8.177 9.057-3.677-8.421-15.761-11.409-15.761-11.409.027 1.177.257 2.366.635 3.528-19.599-13.936-45.727-15.855-45.727-15.855-1.122 1.676-1.717 4.353-1.392 6.313-16.342-1.136-29.251.175-29.251.175-4.23 19.424.865 31.21 6.651 38.01 2.095 2.487 5.109 6.65 13.881 10.867 0 0 9.259 5.718 24.128 6.515 13.3.717 32.494-5.582 32.494-5.582 13.301.135 30.386 41.929 31.035 75.126.149 7.597 7.191-2.487 7.191-2.487s.798-6.704.662-18.572c.027-.094 12.111-53.04 1.366-73.383l-.014.014z"
          fill="url(#paint17_linear_5727_28081)"
        />
        <path
          d="M199.904 47.632c-20.099 5.38-15.571 78.073-15.544 89.982 0 .878-.095 1.432-.23 2.406-.054.432 1.555.378 1.473-.068-.135-.716.068-1.906.136-2.338 3.487-21.911 22.613-69.288 33.657-69.477 0 0-10.354-6.69-19.505-20.505h.013z"
          fill="#874611"
        />
        <path
          opacity={0.11}
          d="M315.081 47.672c-1.906-2.839-4.069-4.569-6.434-5.474-2.149.324-4.393.77-6.691 1.216-6.853 1.325-14.044 2.109-20.965.946-8.056-1.365-15.044-5.217-22.1-9.097-3.568-1.96-7.123-3.946-10.8-5.677-7.893-3.703-17.22-7.393-26.06-7.393-4.379 0-8.705 1.176-12.773 2.69-3.772 1.378-6.921 3.54-9.259 5.744-.501.46-1.555 1.163-2.596 1.852a45.864 45.864 0 00.771 8.854c.432-.244.811-.46.932-.555 1.487-1.027 3.366-2.054 5.515-2.892 2.177-.798 4.583-1.474 7.299-1.595 10.53-.446 20.14 4.095 29.494 8.34 3.825 1.73 7.718 3.73 11.841 5.61 4.109 1.919 8.394 3.743 12.881 5.284 7.57 2.595 16.896 3.623 24.75 1.717 1.068-.257 2.108-.46 3.136-.744 4.068-1.149 7.623-2.676 10.718-4.109 3.096-1.46 5.732-2.798 8.07-3.798a35.877 35.877 0 012.298-.933l-.027.014z"
          fill="#491B00"
        />
        <path
          d="M255.174 101.659s-7.853 22.248 5.123 37.941l32.562-1.662c1.622-5.326-.189-11.476-2.27-19.87-1.69-6.853-35.415-16.409-35.415-16.409z"
          fill="url(#paint18_linear_5727_28081)"
        />
        <path
          d="M200.891 115.54c-3.014 6.353-4.961 20.451-4.961 20.451l30.116 4.623c14.301-15.179 6.474-40.888 6.474-40.888s-28.818 9.88-31.629 15.828v-.014z"
          fill="url(#paint19_linear_5727_28081)"
        />
        <path
          d="M277.991 127.719c-13.612-1.325-17.694 11.881-17.694 11.881s3.663 2.325 17.126 2.068c15.95-.297 15.423-3.731 15.423-3.731s-1.257-8.894-14.869-10.232l.014.014zM195.93 135.991s.379 2.636 12.341 4.448c14.193 2.149 17.775.162 17.775.162s-1.676-8.016-7.259-12.044c-5.582-4.028-15.058-4.082-22.843 7.421l-.014.013z"
          fill="#fff"
        />
        <path
          d="M208.271 140.439c4.839.73 8.448.986 11.097 1 1.636-5.055.419-9.975-2.757-11.057-3.231-1.095-7.272 2.176-9.016 7.326a12.855 12.855 0 00-.595 2.542c.406.067.825.135 1.271.202v-.013zM277.437 141.668c1.851-.04 3.46-.108 4.893-.216 1.176-5.204-.054-10.043-3.028-11.151-3.231-1.217-7.272 2.419-9.016 8.123-.284.906-.459 1.987-.581 3.082 2.122.149 4.663.23 7.732.176v-.014z"
          fill="#5F2F02"
        />
        <path
          d="M213.475 124.61c-9.908-.892-18.194 6.069-17.545 11.381 0 0 4.88-6.691 15.463-5.934 12.449.892 14.653 10.557 14.653 10.557 2.095-4.501 1.162-14.761-12.571-16.004z"
          fill="#874611"
        />
        <path
          d="M199.431 119.771c11.8-10.962 28.398-5.88 33.075-12.314 2.298-3.163-.189-8.15-.189-8.15-29.669.554-32.9 20.451-32.9 20.451l.014.013z"
          fill="url(#paint20_linear_5727_28081)"
        />
        <path
          d="M255.31 99.983s-1.568 5.285.608 7.758c6.785 7.705 23.37 1.798 35.265 12.706 0 0-4.19-23.479-35.873-20.464z"
          fill="url(#paint21_linear_5727_28081)"
        />
        <path
          d="M276.68 124.529c-15.315-.405-18.356 11.381-16.383 15.058 0 0 4.974-8.975 17.167-9.016 13.26-.054 15.395 7.367 15.395 7.367 1.433-5.002-1.027-13.003-16.166-13.409h-.013z"
          fill="#874611"
        />
        <path
          d="M217.3 137.708c-.095 1.757-1.541 3.244-3.217 3.325-1.676.081-2.947-1.271-2.852-3.014.095-1.758 1.541-3.244 3.217-3.326 1.676-.081 2.947 1.271 2.852 3.015zM279.721 138.506c-.095 1.757-1.581 2.974-3.257 3.068-1.677.081-2.92-1-2.812-2.757.095-1.757 1.541-3.244 3.217-3.325 1.676-.081 2.947 1.27 2.852 3.014z"
          fill="#1E1D1D"
        />
        <path
          opacity={0.82}
          d="M212.272 134.383c-1.365.662-2.325 1.96-2.136 2.906.176.946 1.433 1.19 2.798.527 1.366-.662 2.325-1.96 2.136-2.906-.176-.946-1.433-1.189-2.798-.527zM274.26 134.654c-1.474.879-2.46 2.474-2.19 3.582.27 1.095 1.676 1.284 3.163.406 1.473-.879 2.46-2.474 2.19-3.582-.271-1.095-1.676-1.284-3.163-.406z"
          fill="#fff"
        />
        <path
          opacity={0.11}
          d="M291.521 60.527c-7.92 5.893-16.679 9.286-26.236 11.476a2.114 2.114 0 00-.311.108c6.38-1.474 11.111-3.028 11.111-3.028 1.609.014 3.285.649 4.961 1.798 4.366-1.974 8.502-4.488 12.287-7.394 1.757-1.352-.014-4.312-1.825-2.974l.013.014z"
          fill="#000"
        />
        <path
          d="M212.853 178.204s2.946 29.819 31.548 28.048c30.643-1.906 28.453-31.129 28.453-31.129l-60.001 3.081z"
          fill="#FB755C"
        />
        <path
          d="M214.394 178.637c-.446.609 2.365 22.519 29.44 20.83 27.439-1.717 28.385-22.56 28.033-23.141-1.067-1.771-55.838.095-57.473 2.311z"
          fill="url(#paint22_linear_5727_28081)"
        />
        <path
          d="M219.071 177.988s.27 4.231 3.203 7.096c6.542 6.394 37.131 4.69 42.227-1.798 3.352-4.257 3.054-7.596 3.054-7.596l-48.484 2.298z"
          fill="#F7F0F0"
        />
        <path
          opacity={0.32}
          d="M267.555 175.69l-48.484 2.298s.256 3.893 2.865 6.745c1.663-3.434 9.138 1.405 20.83.851 12.53-.594 16.409-4.001 19.018-3.649 1.635.216 1.716 1.149 1.406 2.568a6.37 6.37 0 001.311-1.203c3.352-4.258 3.054-7.597 3.054-7.597v-.013z"
          fill="#C1BAB0"
        />
        <path
          d="M314.311 141.736l-7.218 1.393c-1.392 13.543-5.583 24.519-11.773 29.547-6.367 5.164-14.828 5.515-14.828 5.515 4.893-17.058-32.414-13.179-42.795-12.854-10.381.324-36.671-5.718-32.413 15.855 0 0-12.016-.041-16.842-17.937-2.027-7.515-2.69-21.167-2.906-24.925l-.973-.04c-9.084 35.643-7.827 99.659 61.231 97.753 71.788-1.973 64.408-58.649 64.408-58.649-3.501-12.693 4.109-35.671 4.109-35.671v.013zm-69.342 71.207c-32.737.906-32.264-32.968-31.142-34.549 1.96-2.757 8.421 3.717 27.344 3.933 18.924.217 28.994-8.137 31.224-6.488 2.23 1.649 3.041 36.266-27.426 37.104z"
          fill="url(#paint23_linear_5727_28081)"
        />
        <path
          opacity={0.11}
          d="M238.292 174.946c11.665.068 16.017-5.92 17.626-10.205-7.408-.095-14.517.473-18.208.595-4.393.135-11.651-.865-18.22-.433 1.284 3.785 5.434 9.976 18.802 10.043z"
          fill="#5B2C00"
        />
        <path
          opacity={0.11}
          d="M251.971 216.052c-4.488 1.095-8.002 1.135-10.286.932-2.204-.189-4.015 1.771-3.542 3.934.5 2.298 2.636 4.474 9.313 3.744 6.515-.717 8.354-3.082 8.57-5.258.216-2.176-1.92-3.88-4.055-3.352z"
          fill="#000"
        />
        <path
          d="M235.737 145.561s-11.881-2.365-14.868 7.975c-3.798 13.125 22.343 21.249 29.71 7.57 7.366-13.679-11.138-39.551-11.138-39.551s4.339 13.355-3.704 24.006z"
          fill="#FF9E78"
        />
        <path
          d="M220.571 157.213c1.406 10.827 23.357 16.233 30.007 3.892.406-.756.717-1.54.974-2.365-10.368 2.082-21.114 1.352-30.981-1.527z"
          fill="url(#paint24_linear_5727_28081)"
        />
        <path
          opacity={0.2}
          d="M243.428 135.41l-2.946-.135s-1.676 9.489-4.569 10.786c-.46-.04-.919-.094-1.406-.108-5.15-.243-9.408 1.365-9.516 3.596-.108 2.23 3.988 4.109 9.138 4.352 4.825.23 9.07-.568 9.664-3.176 0 0 2.433-9.908-.378-15.301l.013-.014z"
          fill="#fff"
        />
        <path
          opacity={0.44}
          d="M214.556 144.156c-2.839 0-5.542-.663-8.34-.798-1.527-.081-1.987 2.122-.716 2.825 2.825 1.568 6.42 1.487 9.515 1.014 1.771-.257 1.244-3.041-.459-3.041zM282.167 144.358c-2.879.338-5.609.717-8.515.663-1.758-.027-2.204 2.906-.379 3.149 3.068.419 6.434.406 9.367-.689 1.568-.582 1.433-3.353-.473-3.136v.013z"
          fill="#ED6954"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_5727_28081"
          x={0}
          y={0.0750254}
          width={435.267}
          height={1073.68}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx={18.93} dy={1.80286} />
          <feGaussianBlur stdDeviation={7.25649} />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_5727_28081"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_5727_28081"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_5727_28081"
          x1={319.501}
          y1={403.679}
          x2={394.965}
          y2={403.679}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00C057" />
          <stop offset={1} stopColor="#00A547" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_5727_28081"
          x1={242.205}
          y1={224.826}
          x2={241.614}
          y2={243.862}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#DB7D67" />
          <stop offset={1} stopColor="#FF9E78" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_5727_28081"
          x1={198.2}
          y1={244.432}
          x2={195.188}
          y2={341.355}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00C057" />
          <stop offset={1} stopColor="#00A547" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_5727_28081"
          x1={336.357}
          y1={434.497}
          x2={400.048}
          y2={434.497}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00C057" />
          <stop offset={1} stopColor="#00A547" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_5727_28081"
          x1={38.4317}
          y1={56.7956}
          x2={49.7047}
          y2={84.0861}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFB9A4" />
          <stop offset={1} stopColor="#FF9E78" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_5727_28081"
          x1={56.1521}
          y1={31.5066}
          x2={71.0071}
          y2={67.4614}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFB9A4" />
          <stop offset={1} stopColor="#FF9E78" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_5727_28081"
          x1={88.8769}
          y1={33.4799}
          x2={100.785}
          y2={62.2843}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFB9A4" />
          <stop offset={1} stopColor="#FF9E78" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_5727_28081"
          x1={73.4428}
          y1={73.6644}
          x2={56.9945}
          y2={29.2943}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F28C70" />
          <stop offset={1} stopColor="#FF9E78" />
        </linearGradient>
        <linearGradient
          id="paint8_linear_5727_28081"
          x1={116.627}
          y1={88.8443}
          x2={129.698}
          y2={120.474}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFB9A4" />
          <stop offset={1} stopColor="#FF9E78" />
        </linearGradient>
        <linearGradient
          id="paint9_linear_5727_28081"
          x1={146.093}
          y1={216.862}
          x2={72.1293}
          y2={214.024}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F28C70" />
          <stop offset={1} stopColor="#FF9E78" />
        </linearGradient>
        <linearGradient
          id="paint10_linear_5727_28081"
          x1={5.55946}
          y1={231.596}
          x2={54.193}
          y2={250.019}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFB9A4" />
          <stop offset={1} stopColor="#FF9E78" />
        </linearGradient>
        <linearGradient
          id="paint11_linear_5727_28081"
          x1={200.877}
          y1={355.424}
          x2={194.497}
          y2={545.403}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00C057" />
          <stop offset={1} stopColor="#00A547" />
        </linearGradient>
        <linearGradient
          id="paint12_linear_5727_28081"
          x1={411.672}
          y1={464.45}
          x2={339.884}
          y2={522.978}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F28C70" />
          <stop offset={1} stopColor="#FF9E78" />
        </linearGradient>
        <linearGradient
          id="paint13_linear_5727_28081"
          x1={168.187}
          y1={154.915}
          x2={196.746}
          y2={155.279}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F28C70" />
          <stop offset={1} stopColor="#FF9E78" />
        </linearGradient>
        <linearGradient
          id="paint14_linear_5727_28081"
          x1={246.342}
          y1={67.668}
          x2={246.058}
          y2={89.9149}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#C6664F" />
          <stop offset={1} stopColor="#FF9E78" />
        </linearGradient>
        <linearGradient
          id="paint15_linear_5727_28081"
          x1={303.206}
          y1={177.455}
          x2={332.156}
          y2={177.824}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F28C70" />
          <stop offset={1} stopColor="#FF9E78" />
        </linearGradient>
        <linearGradient
          id="paint16_linear_5727_28081"
          x1={312.677}
          y1={152.337}
          x2={327.153}
          y2={152.522}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F28C70" />
          <stop offset={1} stopColor="#FF9E78" />
        </linearGradient>
        <linearGradient
          id="paint17_linear_5727_28081"
          x1={261.234}
          y1={20.4602}
          x2={258.282}
          y2={75.2737}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E08343" />
          <stop offset={0.26} stopColor="#C16F33" />
          <stop offset={0.82} stopColor="#743C0C" />
          <stop offset={1} stopColor="#5B2C00" />
        </linearGradient>
        <linearGradient
          id="paint18_linear_5727_28081"
          x1={274.276}
          y1={82.0558}
          x2={272.718}
          y2={135.378}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#C6664F" />
          <stop offset={1} stopColor="#FF9E78" />
        </linearGradient>
        <linearGradient
          id="paint19_linear_5727_28081"
          x1={216.569}
          y1={77.7381}
          x2={214.93}
          y2={134.236}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#C6664F" />
          <stop offset={1} stopColor="#FF9E78" />
        </linearGradient>
        <linearGradient
          id="paint20_linear_5727_28081"
          x1={130.172}
          y1={94.5089}
          x2={128.959}
          y2={112.255}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E08343" />
          <stop offset={0.26} stopColor="#C16F33" />
          <stop offset={0.82} stopColor="#743C0C" />
          <stop offset={1} stopColor="#5B2C00" />
        </linearGradient>
        <linearGradient
          id="paint21_linear_5727_28081"
          x1={186.973}
          y1={98.464}
          x2={185.872}
          y2={114.666}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E08343" />
          <stop offset={0.26} stopColor="#C16F33" />
          <stop offset={0.82} stopColor="#743C0C" />
          <stop offset={1} stopColor="#5B2C00" />
        </linearGradient>
        <linearGradient
          id="paint22_linear_5727_28081"
          x1={243.725}
          y1={198.592}
          x2={243.567}
          y2={193.293}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7C302A" />
          <stop offset={0.21} stopColor="#772E28" />
          <stop offset={0.45} stopColor="#692A26" />
          <stop offset={0.7} stopColor="#522421" />
          <stop offset={0.96} stopColor="#321B1A" />
          <stop offset={1} stopColor="#2D1A19" />
        </linearGradient>
        <linearGradient
          id="paint23_linear_5727_28081"
          x1={245.322}
          y1={245.669}
          x2={247.635}
          y2={146.994}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E08343" />
          <stop offset={0.16} stopColor="#DB7F40" />
          <stop offset={0.33} stopColor="#CD7639" />
          <stop offset={0.52} stopColor="#B6672E" />
          <stop offset={0.72} stopColor="#96521D" />
          <stop offset={0.92} stopColor="#6D3809" />
          <stop offset={1} stopColor="#5B2C00" />
        </linearGradient>
        <linearGradient
          id="paint24_linear_5727_28081"
          x1={235.832}
          y1={165.755}
          x2={235.958}
          y2={155.862}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F28C70" />
          <stop offset={1} stopColor="#FF9E78" />
        </linearGradient>
      </defs>
    </svg>
  )
}

export default Castro

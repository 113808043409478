import * as React from "react"

function LambdaIcon(props:any) {
  return (
    <svg
      width={10}
      height={16}
      viewBox="0 0 10 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9.996 15.335c-.137-.569-.307-1.267-.358-1.47a.042.042 0 00-.063-.025c-.152.1-.307.187-.471.251-.323.125-.654.235-1.009.164a1.021 1.021 0 01-.565-.323 2.597 2.597 0 01-.47-.795 8.989 8.989 0 01-.189-.56c-.047-.15-1.665-9.04-1.803-9.57a7.048 7.048 0 00-.223-.672 5.468 5.468 0 00-.519-1.06A3.117 3.117 0 003.55.42C3.187.155 2.783.035 2.34.005c-.482-.032-.936.09-1.388.216C.676.3.417.431.167.58.127.602.12.625.13.672L.48 2.106c.01.045.01.08.076.038.192-.122.396-.228.613-.296.343-.107.691-.199 1.05-.04.222.1.39.26.53.456.172.244.285.517.386.8.039.106.158.69.317 1.514l-3.45 9.809a.02.02 0 00.018.026h1.807a.02.02 0 00.019-.013l2.212-6.58c.389 2.113.757 4.16.785 4.305.038.196.302 1.178.408 1.471.15.42.34.817.582 1.185.265.404.588.75 1.017.973A2.32 2.32 0 007.943 16c.242 0 1.247-.133 2.026-.591.035-.021.035-.04.027-.074z"
        fill="url(#paint0_linear_5734_33059)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_5734_33059"
          x1={7.32655}
          y1={16.9674}
          x2={-1.69381}
          y2={1.67639}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#10D9E8" />
          <stop offset={1} stopColor="#1458F5" />
        </linearGradient>
      </defs>
    </svg>
  )
}

export default LambdaIcon

import React, { ReactNode } from 'react';
import SideMenu from '../components/Dashboard/sideMenu';
import { HeaderDash } from '../components/Dashboard/headerDash';

type LayoutProps = {
  children: ReactNode;
};

export const Layout: React.FC<LayoutProps> = ({ children }) => {
  return (
    <div className='w-full h-screen flex'>
      <div className="transition-transform duration-1000 ease-in ">
        <SideMenu />
      </div>
      <div className="flex flex-col w-full">
        <div className="w-full">
          <HeaderDash />
        </div>
        {children}
      </div>
    </div>
  );
};
